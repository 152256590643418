@font-face {
  font-family: Gods Must;
  src: url('./assets/fonts/Gods\ Must.otf');
}

@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-Black.ttf');
  font-weight: 900;
}
@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-Bold.ttf');
  font-weight: 800;
}
@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-BoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-Light.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-Regular.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Lato;
  src: url('./assets/fonts/Lato-Thin.ttf');
  font-weight: 300;
}

body {
  font-family: 'Lato';
  font-weight: 300;
  margin: 0;

  color: #181818;
  background-color: #EFE9E9;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f6e9c7; */
}

.gm-style-cc { display:none; }

.no-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

:root {
  --bg-color: #f6e9c7;
 }
 
 body {
  background-color: var(--bg-color);
 }